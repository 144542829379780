import React from 'react'
import Layout from '../components/common/Layout'
import { Helmet } from 'react-helmet'

const Post = () => {
    const post = {
        title: "Professional Race Coaches use our Racing Line Editor",
        slug: "professional-race-coaches-racing-line-editor",
        published_at: "2021-07-16",
        published_at_pretty: "July 16, 2021",
        feature_image: "/images/blog/2021/07/racing-line-3D.jpg",
        tags: [{
            name: "Racing Line",
            slug: "racing-line"
        }]
    }

    return (
        <>
            <Helmet>
                <title>{post.title}</title>
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time
                                className="post-full-meta-date"
                                dateTime="2020-04-28T00:00:00.000+00:00"
                            >
                                28 April, 2020
                            </time>
                        </section>
                        <h1 className="post-full-title">
                            Professional Race Coaches use our Racing Line Editor
                        </h1>
                    </header>
                    <figure
                        className="post-full-image"
                        style={{
                            backgroundImage:
                                'url("/images/blog/2021/07/racing-line-3D.jpg")',
                        }}
                    />
                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            {/*kg-card-begin: markdown*/}
                            <p>
                                We are happy to have Dion von Moltke, founder of{" "}
                                <a href="https://racers360.com/about/">
                                    Racers360
                                </a>{" "}
                                as one of our first professional race coaches
                                using our new{" "}
                                <a href="https://open-racer.com/racing-line-editor">
                                    Racing Line Editor
                                </a>
                                .
                            </p>
                            {/*kg-card-end: markdown*/}
                            <figure className="kg-card kg-image-card">
                                <img
                                    src="/images/blog/2021/07/dion-von-moltke.png"
                                    className="kg-image"
                                    alt
                                    loading="lazy"
                                    width={335}
                                    height={255}
                                />
                            </figure>
                            {/*kg-card-begin: markdown*/}
                            <p>
                                Our tool helps him to easily work on racing
                                lines for various tracks around the world. Like
                                him, you can draw acceleration, braking and
                                transition lines directly on satellite photos.
                                We also added an embed functionality so you can
                                add your created lines to your website or blog.
                            </p>
                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe
                                    src="https://www.youtube.com/embed/9qVjMs14jJ0"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <h1 id="how-to-optimize-your-lap-times">
                                How to optimize your lap times
                            </h1>
                            <p>
                                Mastering the racing line is one of the first
                                things to work on when going to a new track. We
                                built a tool that will make the process of
                                analyzing the track and learning reference
                                points more easily.
                            </p>
                            <p>
                                <a href="https://open-racer.com/racing-line-editor">
                                    Draw your racing line now
                                </a>
                            </p>
                            <h2 id="start-with-the-fastest-corners">
                                Start with the fastest corners
                            </h2>
                            <p>
                                Focus on the fastest corners first and then look
                                at the corners leading to a straightaway.
                            </p>
                            <ol>
                                <li>
                                    The geometrical line is usually the best for
                                    fast corners connecting straightaways
                                </li>
                                <li>
                                    Turn in late and apex late for corners
                                    leading to a straightaway
                                </li>
                                <li>
                                    In a set of curves focus on the last corner
                                    leading to a straightaway
                                </li>
                            </ol>
                            <h1 id="compare-lines-and-find-racing-lines-from-other-racers">
                                Compare lines and find racing lines from other
                                racers
                            </h1>
                            <p>
                                We are working on a new version and you will
                                soon be able to learn from other racing lines
                                and compare lines as well.
                            </p>
                            {/*kg-card-end: markdown*/}
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 