import React from 'react'
import Layout from '../components/common/Layout'
import { Helmet } from 'react-helmet'

const Post = () => {
    return (
        <>
            <Layout>
                <Helmet>
                    <title>5 Reasons Why You Suck at Go Karting</title>
                    <meta name="description" content="Learn the common mistakes that make you slower at go-karting and how to fix them. From corner entry to track width usage, improve your racing skills." />
                    <meta property="og:title" content="5 Reasons Why You Suck at Go Karting" />
                    <meta property="og:description" content="Learn the common mistakes that make you slower at go-karting and how to fix them. From corner entry to track width usage, improve your racing skills." />
                </Helmet>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date" dateTime="2020-07-27T00:00:00.000+00:00">
                                27 July, 2020
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">5 Reasons Why You Suck at Go Karting</h1>
                    </header>

                    <figure className="post-full-image" style={{ backgroundImage: 'url("/images/blog/2021/07/go-karting-fail-1.jpg")' }}></figure>

                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            <p>Are you stuck and haven't improved your lap times since a while? Get rid of the following<br />
                            five problems and you will win your next race.</p>

                            <h1 id="1-you-enter-the-corner-too-fast">1. You Enter The Corner Too Fast</h1>
                            <p>This applies mostly to rental karts but it is so common that I'm starting with this one. It sounds so contradictory and it was my biggest fault I was committing repeatedly. I keep seeing it constantly when I race with my friends.</p>

                            <p>Go kart novices want to achieve too much especially when your friend is behind and chasing you.</p>

                            <p>Being too fast inside the corner results in sliding, lose of engine RPM - because rental engines are comparatively weak - and you are not going to recoup those valuable tenths of seconds throughout the lap.</p>

                            <p>It adds up and at the end of the lap you are a couple of seconds behind the winner.</p>

                            <p>The next time you are on the track, consciously try to brake earlier entering the corner slowlier but hitting the gas earlier as well. You will notice the difference and be ahead of your friends 😉.</p>

                            <h1 id="2-you-do-not-know-what-threshold-braking-is">2. You Do Not Know What Threshold Braking Is</h1>
                            <p>That's the follow up of the first point in case you have managed well the corner entering speed and hitting the gas early. Now it's time to be faster inside the corner too to squeeze some more time out of your laps.</p>

                            <p>There are two techniques to slow down inside corners:</p>
                            <ul>
                                <li>braking</li>
                                <li><strong>controlled drifting</strong></li>
                            </ul>

                            <p>Threshold braking is the technical term for the latter one. A drift converts the kinetic energy into friction and slows down the vehicle. We can leverage this effect to have higher corner entering speed and still hit the gas early at the apex.</p>

                            <p>It takes a little to practice and therefore to see the positive effects on your lap times<br />
                            but once you conquered this technique it's going to leave everybody behind you.</p>

                            <p>To get a closer look on how to do it I wrote a more thorough <a href="https://open-racer.com/how-to-get-faster-at-go-karting">How to Get Faster at Go Karting</a> post.</p>

                            <h1 id="3-you-have-not-studied-the-race-line">3. You Have Not Studied the Race Line</h1>
                            <p>You definitely should study it. It is one cornerstone of being fast and keeping your lap<br />
                            times constant throughout the whole race.</p>

                            <p>There are racers who intuitively just get it right and there are those who need to practice a lot. Trial and error of different lines will make you better but it is a time consuming process so I advise to study the race line before racing.</p>

                            <p><strong>But how to know which line is the fastest?</strong></p>

                            <p>The race line doesn't only depend on the track itself but also on the vehicle (tyres, trim, etc.), weather conditions and many more.<br />
                            It's a quite complex interaction between the driver, vehicle and environment and needs a lot of experience.</p>

                            <p>Usually coaches do their best to teach racers their knowledge about the race lines but who can afford a coach...?</p>

                            <p>That's why we created a <a href="https://open-racer.com/racing-line-editor">race line editor</a> so that you can learn from experienced racers about which line to take the next time when you are on the track.</p>

                            <p>Draw your own line and ask for feedback or look for existing ones! We are happy to help 😊.</p>

                            <h1 id="4-you-do-not-use-the-full-width-of-the-track">4. You Do Not Use the Full Width of the Track</h1>
                            <p>Do you? Probably you aren't aware of not using it. The track is to be used in its complete width even the curbs - those often red-white painted bumpy thingies on the<br />
                            outer side of the corner's ground.</p>

                            <p>The fact that you don't use the whole track's width results in more steering than necessary. The opposite actually should be the ultimate goal.<br />
                            Steering more than necessary creates more friction and makes you lose speed.</p>

                            <p><strong>Let the angular force pull you to the outer side of the track when leaving the corner!</strong></p>

                            <p>It's an automatic process which physics takes care of. It can be tempting to impede this effect but it should be your friend as long as you stay on the track. Don't fly off the track!</p>

                            <h1 id="5-you-are-not-on-a-drifting-competition">5. You Are Not On a Drifting Competition</h1>
                            <p>A lot of karters drift a lot although they want to be fast and win. Drifting and being fast don't play well together. Either or.</p>

                            <p>Drifting might be fun but needs to be avoided if you want to decrease your lap times. I know that it's quite difficult to act upon the "no drifting principle" because we all want to be faster than the others - it's too easy to approach the curves too fast - and finally end up in the drifting competition.</p>

                            <p>This point closes the relation to the "enter the corner slowlier" point one.</p>

                            <p>That's all for now. Let's go onto the track and give it a try! ✌</p>
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 