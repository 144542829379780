import React from 'react'
import Layout from "../components/common/Layout"
import { Helmet } from 'react-helmet'

const Post = () => {
    const post = {
        title: "Professional low-budget Race Data Logging Gear",
        slug: "low-budget-race-data-logging-gear",
        published_at: "2021-06-08",
        published_at_pretty: "June 8, 2021",
        feature_image: "/images/blog/2021/06/photo-eyJhcHBfaWQiOjF9.jpg",
        tags: [{
            name: "Data Logging",
            slug: "data-logging"
        }]
    }

    return (
        <>
            <Helmet>
                <title>{post.title}</title>
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time
                                className="post-full-meta-date"
                                dateTime="2018-12-25T08:43:00.000+00:00"
                            >
                                25 December, 2018
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">
                            Professional low-budget Race Data Logging Gear to
                            get Faster
                        </h1>
                    </header>
                    <figure
                        className="post-full-image"
                        style={{
                            backgroundImage:
                                'url("/images/blog/2021/06/photo-eyJhcHBfaWQiOjF9.jpg")',
                        }}
                    />
                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            {/*kg-card-begin: markdown*/}
                            <p>
                                Get your race data logger and work on your lap
                                times like professional race drivers. Analyze
                                your split times and compare throttle position
                                and corner speed to maximize your time on the
                                throttle.
                            </p>
                            <blockquote>
                                <p>
                                    Build your own Racing data logger using your
                                    smartphone and save thousand of dollars
                                    compared to all-in-one data logging
                                    solutions.
                                </p>
                            </blockquote>
                            <h2 id="smartphone-app-gopro-external-gps-obd2">
                                Smartphone App + GoPro + External GPS + OBD2
                            </h2>
                            <p>
                                Your smartphone has extensive computing
                                capabilities and enables you to use it as a
                                racing station. In combination with your action
                                cam it will be great for sharing your laps as
                                high quality video with fancy racing overlays.
                            </p>
                            <ul>
                                <li>
                                    iPhone and Android phone as base station and
                                    lap timer display
                                </li>
                                <li>Your GoPro action cam</li>
                                <li>
                                    External bluetooth GPS sensor for awesome
                                    metrics
                                </li>
                                <li>
                                    OBD2 bluetooth – connected with your car to
                                    gather throttle position, RPM and much more
                                </li>
                            </ul>
                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe
                                    src="https://www.youtube.com/embed/mtiWDlf-PH0"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <h2 id="racechrono-app">RaceChrono App</h2>
                            <p>
                                The RaceChrono App enables you to get started
                                quickly. After installing the free version you
                                can search for existing race tracks. If your
                                preferred track is not listed you can even
                                create one in just a few minutes. The go-kart
                                circuit in my area wasn't listed so I set the
                                markers in the map within the app and I was
                                ready to race.
                            </p>
                            <blockquote>
                                <p>
                                    Like advanced professional lap timers it
                                    will automatically recognize the
                                    start/finish line and start your lap when
                                    you pass the position.
                                </p>
                            </blockquote>
                            <h2 id="action-cam">Action Cam</h2>
                            <p>
                                Your GoPro is a great camera with built in
                                stabilization features. I would recommend to
                                start with a full hd quality setup. This will
                                make the transfer to your phone quicker and
                                enable you to analyze your data directly on the
                                phone.
                                <br />
                                To mount your camera I can recommend the{" "}
                                <a href="https://www.amazon.com/Gecko-Mini-Camera-Camcorder-Mount/dp/B002K8Q0EU">
                                    Gecko Mini Mount
                                </a>
                                .
                            </p>
                            <p>
                                Use an external bluetooth GPS sensor
                                <br />
                                Even though your smartphone has a built in GPS
                                sensor in most cases it won't be fast enough to
                                gather enough position data points. I am using
                                the{" "}
                                <a href="https://www.amazon.de/Garmin-010-01055-15-GLO-GPS-Empf%C3%83%C2%A4nger-Schwarz/dp/B00AYAZENY/">
                                    GARMIN GLO device
                                </a>
                                .
                            </p>
                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe
                                    src="https://www.youtube.com/embed/1H364VhU9Pk"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <h2 id="connect-your-car-using-obd2">
                                Connect your car using OBD2
                            </h2>
                            <p>
                                Throttle position and RPM information will help
                                you to work on your driving skills and get even
                                faster. An OBD2 bluetooth connector is working
                                with all production based cars built after the
                                year 2001. Plug-in to your car, connect via
                                bluetooth and enable in RaceChrono to gather
                                your data.
                            </p>
                            <p>
                                When I analyze my laps I often see spots where I
                                could go faster and stay longer on the throttle.
                                Without this additional information it's hard to
                                find out how you interact with the engine.
                            </p>
                            <h2 id="find-out-where-to-get-faster">
                                Find out where to get faster
                            </h2>
                            <p>
                                Right after your first laps you can grab your
                                phone and take a look at your section times and
                                compare your laps.
                            </p>
                            <ol>
                                <li>Enable the time delta chart metric</li>
                                <li>
                                    Look at time delta peeks and find your
                                    fastest sections times
                                </li>
                                <li>
                                    Compare your line, corner entry speed and
                                    speed at the apex
                                </li>
                            </ol>
                            {/*kg-card-end: markdown*/}
                            <figure className="kg-card kg-image-card kg-width-wide">
                                <img
                                    src="/images/blog/2021/06/RaceChrono-1.gif"
                                    className="kg-image"
                                    alt
                                    loading="lazy"
                                    width={350}
                                    height={645}
                                />
                            </figure>
                            {/*kg-card-begin: markdown*/}
                            <h2 id="dig-deeper-on-a-big-screen">
                                Dig Deeper on a big screen
                            </h2>
                            <p>
                                After each track day I am exporting my sessions
                                from RaceChrono as VBO files and open them on my
                                Mac or Windows PC using the free{" "}
                                <a href="https://www.vboxmotorsport.co.uk/index.php/en/customer-area/software">
                                    Circuit Tools desktop software
                                </a>
                                . When heading to the next track day I also look
                                at my last sessions and define corners and
                                sections to work on.
                            </p>
                            {/*kg-card-end: markdown*/}
                            <figure className="kg-card kg-image-card kg-width-wide">
                                <img
                                    src="/images/blog/2021/06/racelogic-karting-1.jpg"
                                    className="kg-image"
                                    alt
                                    loading="lazy"
                                    width={1200}
                                    height={754}
                                    srcSet="/images/blog/size/w600/2021/06/racelogic-karting-1.jpg 600w, /images/blog/size/w1000/2021/06/racelogic-karting-1.jpg 1000w, /images/blog/2021/06/racelogic-karting-1.jpg 1200w"
                                    sizes="(min-width: 1200px) 1200px"
                                />
                            </figure>
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 