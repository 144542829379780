import React from "react"
import PropTypes from "prop-types"
import { Tags } from "@tryghost/helpers-gatsby"

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`

    return (
        <article className="post-card">
            <a href={url} className="post-card-image-link">
                <div className="post-card-image">
                    <img 
                        src={post.feature_image} 
                        alt={post.title}
                        className="object-cover w-full h-full"
                    />
                </div>
            </a>
            <div className="post-card-content">
                <a href={url} className="post-card-content-link">
                    <header className="post-card-header">
                        {post.tags && post.tags.length > 0 && (
                            <div className="post-card-primary-tag">{post.tags[0].name}</div>
                        )}
                        <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <section className="post-card-excerpt">{post.excerpt}</section>
                </a>
            </div>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
