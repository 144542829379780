import React from 'react'
import Layout from '../components/common/Layout'
import { Helmet } from 'react-helmet'

const Post = () => {
    return (
        <>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date" dateTime="2020-08-01T00:00:00.000+00:00">
                                01 August, 2020
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">Your GoPro Might Be Your Best Data Logger</h1>
                    </header>

                    <figure className="post-full-image" style={{ backgroundImage: 'url("/images/blog/2021/07/go-karting-360-shot.jpeg")' }}></figure>

                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            <p>I love the video quality of my GoPro and it turned out, it's a great data logger as well!</p>

                            <p><a href="https://open-racer.com/Professional-low-budget-Race-Data-Logging-Gear">As I described in an earlier post</a>, I have used an external GPS tracker, and my phone in my pocket while driving on the track.</p>

                            <p>My setup worked good, but it required a lot of steps on the track.</p>

                            <p>I had to connect all devices via Bluetooth and make sure all batteries are full also.</p>

                            <p>In addition, I had to mount my GPS tracker and watch out for my phone while driving.</p>

                            <h1 id="awesome-18hz-gps-sensor">Awesome 18Hz GPS Sensor</h1>
                            <p>I found out that all the new GoPros (Hero 5+) come with a built-in 18Hz GPS sensor.</p>

                            <p>This is great, since my external Garmin GPS sensor has an update rate of only 10Hz.</p>

                            <p>GoPro has also released all metadata on an open source project on <a href="https://github.com/gopro/gpmf-parser">GitHub</a>. The open format enables tool providers to fetch the GPS metadata from the video file.</p>

                            <p>This meant for me that I could drop the external GPS sensor.</p>

                            <p>GPS information is stored right in the video files. This means that I don't need my phone while driving anymore.</p>

                            <p>Just start the video recording and I'm ready to go!</p>

                            <h1 id="telemetry-data-from-mp4-files">Telemetry Data From MP4 Files</h1>
                            <p>The location data is saved to every MP4 file.</p>

                            <p>This is great because the RaceChrono app can import the data directly from a video file.</p>

                            <p>A video I made with my camera mounted on my helmet:</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/HqbNbHZYZpE" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <p><strong>How to record and analyze the data from your GoPro:</strong></p>
                            <ol>
                                <li>Start the camera and make sure you are outside, so you won't miss the first laps until your GoPro is looking for signal</li>
                                <li>Record your laps</li>
                                <li>Transfer the MP4 to your phone</li>
                                <li>Import the video file to the RaceChrono app (or Race Render if you use it on a desktop)</li>
                                <li>Analyze your data and share the fastest lap!</li>
                            </ol>

                            <p>After each session I will export my session and analyze it on my desktop to see where I can go faster.</p>

                            <h1 id="360-videos-with-telemetry-data">360° Videos With Telemetry Data</h1>
                            <p>The new GoPro Max 360° camera also supports recording of GPS data.</p>

                            <p>I can record a session and decide later, what part of the video I would like to cut out. This is a video edit from a go-karting race with some friends:</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/DStCkApjoFU" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <h2 id="how-to-export-your-360-video-with-gps-data">How To Export Your 360° Video With GPS Data</h2>
                            <p>To prepare your 360 video file you can download the <a href="https://community.gopro.com/t5/en/GoPro-Player/ta-p/413305">Player</a> and edit it right in the app.</p>

                            <p>Make sure you enable telemetry data when you export a video.</p>

                            <figure className="kg-card kg-image-card kg-width-wide">
                                <img src="/images/blog/2021/07/360-export-gps-telemetry-data.png" 
                                     className="kg-image" 
                                     alt="GoPro 360 Telemetry Export" 
                                     loading="lazy" />
                            </figure>

                            <p>I made this video using <a href="http://racerender.com/RR3/Features.html">Race Render</a> which supports 360 video files as well:</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/9KgqxRLCQ_k" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <p>Happy racing! 🏁</p>
                        </div>
                    </section>
                </article>
            </Layout>
            <Helmet>
                <title>Your GoPro Might Be Your Best Data Logger</title>
                <meta name="description" content="Discover how to use your GoPro as a powerful data logging tool for racing. Learn about the built-in GPS capabilities and how to analyze your racing data." />
                <meta property="og:title" content="Your GoPro Might Be Your Best Data Logger" />
                <meta property="og:description" content="Discover how to use your GoPro as a powerful data logging tool for racing. Learn about the built-in GPS capabilities and how to analyze your racing data." />
            </Helmet>
        </>
    )
}

export default Post 