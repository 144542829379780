module.exports = {
    siteUrl: process.env.GATSBY_SITEURL, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Open Racer`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Getting Faster. On and to Race Tracks.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Open Racer`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#f5f5f5`, // Used for Offline Manifest
    themeColor: `#5159a2`, // Used for Offline Manifest

    author: {
        name: "Gabor",
        bio: "Racing enthusiast and go-kart expert. Passionate about helping others improve their racing skills.",
        image: "/images/gabor-profile.jpg",
        website: "https://open-racer.com"
    }
}
