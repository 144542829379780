import React from 'react'
import Layout from '../components/common/Layout'
import { Helmet } from 'react-helmet'

const Post = () => {
    return (
        <>
            <Helmet>
                <title>I've improved my lap times by 2 secs using my GoPro and iPad</title>
                <meta name="description" content="Learn how to use your GoPro and iPad to analyze your racing data and improve your lap times. A simple setup for better racing performance." />
                <meta property="og:title" content="I've improved my lap times by 2 secs using my GoPro and iPad" />
                <meta property="og:description" content="Learn how to use your GoPro and iPad to analyze your racing data and improve your lap times. A simple setup for better racing performance." />
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date" dateTime="2021-07-16T12:25:19.000+00:00">
                                16 July, 2021
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">I've improved my lap times by 2 secs using my GoPro and iPad</h1>
                    </header>

                    <figure className="post-full-image" style={{ backgroundImage: 'url("/images/blog/2021/07/go-kart-ipad-data-logger.jpg")' }}></figure>

                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            <p>Yesterday, I went to the super nice <a href="https://open-racer.com/go-karting/portimao">Portimao go-kart track in Portugal</a> and rented a Rotax kart. The kart came with no racing data logger. Fortunately, I brought my helmet and my GoPro with me.</p>

                            <p>My goal was to learn from each session and do some data analysis before the next stint. This way, I could see if my changes lead to a faster section-time or not.</p>

                            <p>The RaceChrono App is great, and <a href="https://open-racer.com/go-karting-data-logger">I've used it in the past</a>. My problem with the solution was that it took me ages to move the video files to the phone and import the data. The small screen and the limited storage size on my phone made this process a real pain. I had to delete a ton of files all the time. So annoying.</p>

                            <p>Fortunately, I figured out that the app was also available as an iPad version.</p>

                            <h1 id="so-i-did-the-following-to-make-my-process-smoother">So, I did the following to make my process smoother:</h1>
                            <ol>
                                <li>Turned down the quality of the videos (1080p)</li>
                                <li>Setup the GoPro app + RaceChrono app on my iPad</li>
                                <li>Transferred the video files to the local storage after the session</li>
                                <li>Imported the video files to RaceChrono over Wifi</li>
                                <li>Analysed and compared the laps and section times</li>
                            </ol>

                            <figure className="kg-card kg-image-card">
                                <img src="/images/blog/2021/07/ipad-gopro-helmet.jpg" 
                                     className="kg-image" 
                                     alt="Data analysis" 
                                     loading="lazy" />
                            </figure>

                            <p>The upload process took around 3-4 mins and I could dig into my data right on the big screen. I did not need an internet connection nor a power cord. The iPad's battery didn't give me a hard time. It hardly lost any battery power.</p>

                            <p>I was able to find the spots on the track and learn from the data.</p>

                            <blockquote>
                                <p>Within only a few sessions, I was able to bring my lap times down by more than 2 seconds.</p>
                            </blockquote>

                            <p>I also like that I'm able to export an individual lap as a video with a nice overlay on top of it:</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/OUtYWKAC7Ko" 
                                    title="YouTube video player" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <p>I think I've found my minimal setup to do my data analysis now, and I can use it on any go-karting track. Will keep you posted.</p>
                            
                            <p>Happy racing</p>
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 