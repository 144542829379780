import React from 'react'
import PostCard from '../common/PostCard'
import { posts } from '../../data/posts'

const BlogPosts = () => {
    return (
        <section className="post-feed">
            {posts.map((post, i) => (
                <PostCard key={post.slug} post={post} />
            ))}
        </section>
    )
}

export default BlogPosts