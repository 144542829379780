import React from 'react'
import { Layout } from '../components/common'
import { Helmet } from 'react-helmet'
import PostCard from '../components/common/PostCard'

const Tag = ({ pageContext }) => {
    const { tag, posts } = pageContext

    return (
        <>
            <Helmet>
                <title>{tag.name} - Go Karting Articles</title>
                <meta name="description" content={`Articles about ${tag.name.toLowerCase()} in go-karting. Tips, techniques, and insights to improve your racing.`} />
                <meta property="og:title" content={`${tag.name} - Go Karting Articles`} />
                <meta property="og:description" content={`Articles about ${tag.name.toLowerCase()} in go-karting. Tips, techniques, and insights to improve your racing.`} />
            </Helmet>
            <Layout>
                <header className="py-16 text-center bg-gray-50">
                    <h1 className="text-4xl font-bold text-gray-900">{tag.name}</h1>
                </header>
                <section className="post-feed">
                    {posts.map((post) => (
                        <PostCard key={post.slug} post={post} />
                    ))}
                </section>
            </Layout>
        </>
    )
}

export default Tag 