const posts = [
    {
        title: "Unveiling the Thrills of Go Karting: Insights from the 2023 Survey",
        slug: "unveiling-the-thrills-of-go-karting-insights-from-the-2023-survey",
        feature_image: "/images/blog/2023/11/Unveiling-the-Thrills-of-Go-Karting_-Insights-from-the-2023-Survey-Large.jpg",
        published_at: "2023-11-16",
        excerpt: "Discover the latest trends and preferences in go-karting from our 2023 survey. Learn about visit frequency, track preferences, and racing modes.",
        tags: [{ name: "Getting Faster", slug: "getting-faster" }]
    },
    {
        title: "End Go-Kart Race Organization Chaos! Streamline Booking & Fuel Racer Excitement",
        slug: "go-kart-race-booking-software",
        feature_image: "/images/blog/2024/04/go-karting-booking-software.jpeg",
        published_at: "2024-04-02",
        excerpt: "Discover a go-kart race management solution that saves you time, simplifies logistics, and maximizes track revenue. Streamline your race operations today!",
        tags: []
    },
    {
        title: "I've improved my lap times by 2 secs using my GoPro and iPad",
        slug: "ive-improved-my-lap-times-by-2-secs-using-my-gopro-and-ipad-2",
        feature_image: "/images/blog/2021/07/go-kart-ipad-data-logger.jpg",
        published_at: "2021-07-16",
        excerpt: "Learn how to use your GoPro and iPad to analyze your racing data and improve your lap times. A simple setup for better racing performance.",
        tags: [{ name: "Getting Faster", slug: "getting-faster" }]
    },
    {
        title: "Your GoPro Might Be Your Best Data Logger",
        slug: "your-gopro-might-be-your-best-data-logger",
        feature_image: "/images/blog/2021/07/go-karting-360-shot.jpeg",
        published_at: "2020-08-01",
        excerpt: "Discover how to use your GoPro as a powerful data logging tool for racing. Learn about the built-in GPS capabilities and how to analyze your racing data.",
        tags: [{ name: "Getting Faster", slug: "getting-faster" }]
    },
    {
        title: "5 Reasons Why You Suck at Go Karting",
        slug: "5-reasons-why-you-suck-at-go-karting",
        feature_image: "/images/blog/2021/07/go-karting-fail-1.jpg",
        published_at: "2020-07-27",
        excerpt: "Learn the common mistakes that make you slower at go-karting and how to fix them. From corner entry to track width usage, improve your racing skills.",
        tags: [{ name: "Getting Faster", slug: "getting-faster" }]
    },
    {
        title: "How to Get Faster at Go Karting",
        slug: "how-to-get-faster-at-go-karting",
        feature_image: "/images/blog/2021/07/go-kart-track-aerial.jpg",
        published_at: "2019-09-10",
        excerpt: "Learn essential go-karting techniques like threshold braking and controlled drifting to improve your lap times. Practical tips for faster racing.",
        tags: [{ name: "Getting Faster", slug: "getting-faster" }]
    },
    {
        title: "Race Data Logger for Rental Go Karts",
        slug: "go-karting-data-logger",
        feature_image: "/images/blog/2021/06/karting.jpg",
        published_at: "2021-06-08",
        excerpt: "To get faster on a go kart track it's helpful if you can learn from every lap you do on a track. I have found a nice setup to record my split times and analyze the data on my phone directly.",
        tags: [{ name: "Data Logging", slug: "data-logging" }]
    },
    {
        title: "5 Speed Tactics To Win Your First Race",
        slug: "5-speed-tactics-to-win-a-race",
        feature_image: "/images/blog/2021/07/podium-winning-racing.jpg",
        published_at: "2020-07-20",
        excerpt: "Master these 5 essential speed tactics to improve your racing performance and win your first go-kart race. Expert tips for racers of all levels.",
        tags: [{ name: "Racing", slug: "racing" }]
    },
    {
        title: "Professional low budget Race Data Logging Gear",
        slug: "professional-race-coaches-racing-line-editor",
        feature_image: "/images/blog/2021/07/racing-line-3D.jpg",
        published_at: "2019-08-15",
        excerpt: "Learn about affordable race data logging equipment that can help improve your racing performance without breaking the bank.",
        tags: [{ name: "Data Logging", slug: "data-logging" }]
    }
]

module.exports = {
    posts
}