import React from 'react'
import Layout from '../components/common/Layout'
import { Helmet } from 'react-helmet'

const Post = () => {
    return (
        <>
            <Helmet>
                <title>How to Get Faster at Go Karting</title>
                <meta name="description" content="Learn essential go-karting techniques like threshold braking and controlled drifting to improve your lap times. Practical tips for faster racing." />
                <meta property="og:title" content="How to Get Faster at Go Karting" />
                <meta property="og:description" content="Learn essential go-karting techniques like threshold braking and controlled drifting to improve your lap times. Practical tips for faster racing." />
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date" dateTime="2019-09-10T00:00:00.000+00:00">
                                10 September, 2019
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">How to Get Faster at Go Karting</h1>
                    </header>

                    <figure className="post-full-image" style={{ backgroundImage: 'url("/images/blog/2021/07/go-kart-track-aerial.jpg")' }}></figure>

                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            <p>Follow these techniques and you are going to improve your lap times dramatically. I use it and it helped me to improve my lap time by 2 seconds on a 52 seconds lap from one round to the other.</p>

                            <h2 id="threshold-braking">Threshold Braking</h2>
                            <p>This article is going to describe a commonly used technique in go karting to achieve faster cornering. Many novices don't use it because it doesn't seem natural or logical at first sight but I'm going to explain so you can start applying it immediately the next time you are on the go kart track. And maybe slap your faster friend.</p>

                            <p>I focus on gas powered rental karts that have rear brakes only because those are the ones that most hobbyists deal with.</p>

                            <h2 id="tips">Tips</h2>
                            <p><strong>To be fast you need concentrate on the curves.</strong> Since all racers have the same karts – yeah more or less – you cannot accelerate or decelerate better on the straight than your fellows. So this tactic is out of business.</p>

                            <p>One rule of thumb while driving is that you should <strong>never roll</strong>. Either hit the gas or the brake pedals. Rolling means losing speed because you could have hit the gas even longer. Hitting the gas usually is an all or nothing proposition. No need to slowly accelerate because rental karts don't have overpowered engines that would make you slide.</p>

                            <p>The most common way to decelerate in front of a curve – you don't want to get off the track, I guess – is....... obviously braking. But there's another: <strong>controlled drifting inside the curve.</strong></p>

                            <p>A kid is practicing threshold braking a couple of rounds. Especially at end there is a slow motion to get a better feeling how it may look like.</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/GGqj6PkvAB0" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <p>It has some benefits. Normally we learn that braking should be finished before cornering because it makes the vehicle unstable. That's true but in racing the right amount of unstability is exactly what we want. The curve entry speed can be higher because still the kart is going to decelerate via drifting. Shortly after initiating the drift before the apex point you can hit the gas again so that the engine gains RPM. There's maybe a really short period where you aren't on the brake nor on the gas which breaks my first rule but you get the idea.</p>

                            <h2 id="how-to-do-it">How to do it?</h2>
                            <p>How to initiate the drift? Well, first <strong>get your weight to the front</strong>! This sounds paradoxical but it makes the kart more oversteering thus more unstable thus "easy" to start the drift. Sometimes you need to activate the drift with a short but hard braking maneuver and blocking tires. It depends on how strong the kart's oversteering tendency is. The more oversteering the less you need to do. Moving the seat to the front usually is the only way to achieve more front weight. From time to time I see others moving it to the rear because they want to have space for their feet but go karting is not like driving a comfortable limousine. I'm tall and I don't do it either so you probably shouldn't.</p>

                            <h2 id="practice-and-patience">Practice and Patience!</h2>
                            <p>Of course this technique is advanced and it's a sharp edge between too much drifting and the right amount. Too much drift makes you slower. All pros do it. It's subtle to notice but looking closely you'll see it. It's the karting way of "trail braking".</p>

                            <p>In this video you can see the drifting clearly and the guys explain more or less what I describe. They drive indoor and it's way more slippery therefore maybe easier to practice. You don't need to push the kart so hard to destabilize.</p>

                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe 
                                    src="https://www.youtube.com/embed/8vefLpfozPA" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <p>I also tried the technique with rear weight distribution and it doesn't work at all. The kart constantly wants to go straight while I want to corner.</p>

                            <h2 id="summary">Summary</h2>
                            <ul>
                                <li>Gas or brake, no rolling</li>
                                <li>Create oversteering go kart via moving the seat to the front</li>
                                <li>Shorter braking, controlled drifting to lose speed</li>
                            </ul>

                            <p>As always practice makes you better so let's get onto the track.</p>

                            <p>Happy racing!</p>
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 