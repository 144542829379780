import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import Gokart from '../components/tracks/Gokart'
import BlogPosts from '../components/common/BlogPosts'
import racingImage from '../images/racing-1600x900.jpg'

const Index = ({ data, location, pageContext }) => {
    const geo = data.geo
    const indexTitle = "Go Karting, and Race Tracks Near You"
    const indexDesc = "Find go kart and car tracks for all your favorite races in your city. Join races with Open Racer."
    const tracks = pageContext.tracks?.map(track => track.document)

    return (
        <>
            <MetaData
                location={location}
                title={
                    geo
                        ? `${geo.city} Go Karting Tracks and Racing Lines`
                        : indexTitle
                }
                description={
                    geo
                        ? `Find go karting tracks near you. Motorsport tracks and events in ${geo.city}, ${geo.country}.`
                        : indexDesc
                }
                image={racingImage}
            />
            <Layout>
                <Gokart geo={geo} tracks={tracks} />
                <BlogPosts />
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
  query IndexQuery($filename: String = "") {
    geo: worldcitiesFilteredCsv(filename: {eq: $filename}) {
        filename
        city
        lat
        lng
        city_ascii
        country
    }
  }
`
