import React from 'react'
import Layout from "../components/common/Layout"
import { Helmet } from 'react-helmet'

const Post = () => {
    const post = {
        title: "Race Data Logger for Rental Go Karts",
        slug: "go-karting-data-logger",
        published_at: "2021-06-08",
        published_at_pretty: "June 8, 2021",
        feature_image: "/images/blog/2021/06/racelogic-karting-2.jpg",
        tags: [{
            name: "Data Logging",
            slug: "data-logging"
        }]
    }

    return (
        <>
            <Helmet>
                <title>Race Data Logger for Rental Go Karts</title>
                <meta name="description" content="Learn how to use data logging tools to improve your rental go-kart racing performance. Essential tips and techniques for faster lap times." />
                <meta property="og:title" content="Race Data Logger for Rental Go Karts" />
                <meta property="og:description" content="Learn how to use data logging tools to improve your rental go-kart racing performance. Essential tips and techniques for faster lap times." />
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time
                                className="post-full-meta-date"
                                dateTime="2019-09-10T00:00:00.000+00:00"
                            >
                                10 September, 2019
                            </time>
                            <span className="date-divider">/</span>
                            <a href="/tag/getting-faster">Getting Faster</a>
                        </section>
                        <h1 className="post-full-title">
                            Race Data Logger for Rental Go Karts
                        </h1>
                    </header>
                    <figure
                        className="post-full-image"
                        style={{
                            backgroundImage:
                                'url("/images/blog/2021/06/karting.jpg")',
                        }}
                    />
                    <section className="post-full-content">
                        <div className="kg-card-markdown">
                            {/*kg-card-begin: markdown*/}
                            <p>
                                To get faster on a go kart track it's helpful if
                                you can learn from every lap you do on a track.
                                I have found a nice setup to record my split
                                times and analyze the data on my phone directly.
                                I like that I can use it for any outdoor rental
                                kart.
                            </p>
                            <blockquote>
                                <p>
                                    Build your own Racing data logger using your
                                    smartphone and tape it to your helmet.
                                </p>
                            </blockquote>
                            <h2 id="smartphone-app-gopro-external-gps">
                                Smartphone App + GoPro + External GPS
                            </h2>
                            <p>
                                I use my smartphone as a racing station and have
                                it with me in my pocket while racing. In
                                combination with my action cam mounted on the
                                helmet it's great for sharing the laps as high
                                quality video with racing overlays.
                            </p>
                            <ul>
                                <li>
                                    iPhone and Android phone as base station and
                                    lap timer display
                                </li>
                                <li>GoPro action cam</li>
                                <li>
                                    External bluetooth GPS sensor taped on the
                                    helmet
                                </li>
                            </ul>
                            <div className="w-full kg-embed-card aspect-w-16 aspect-h-9">
                                <iframe
                                    src="https://www.youtube.com/embed/1H364VhU9Pk"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <h2 id="racechrono-app-for-outdoor-karting">
                                RaceChrono App for Outdoor Karting
                            </h2>
                            <p>
                                The RaceChrono App enables you to get started
                                quickly. After installing the free version you
                                can search for existing race tracks. If your
                                preferred track is not listed you can even
                                create one in just a few minutes. The go-kart
                                circuit in my area wasn't listed so I set the
                                markers in the map within the app and I was
                                ready to race.
                            </p>
                            <blockquote>
                                <p>
                                    Like advanced professional lap timers it
                                    will automatically recognize the
                                    start/finish line and start your lap when
                                    you pass the position.
                                </p>
                            </blockquote>
                            <h2 id="action-cam-mounted">Action Cam mounted</h2>
                            <p>
                                Your GoPro is a great camera with built in
                                stabilization features. I would recommend to
                                start with a full hd quality setup. This will
                                make the transfer to your phone quicker and
                                enable you to analyze your data directly on the
                                phone.
                            </p>
                            <p>
                                Even though your smartphone has a built in GPS
                                sensor in most cases it won't be accurate and
                                fast enough to gather enough position data
                                points for your outdoor go karting track. I am
                                using the{" "}
                                <a href="https://www.amazon.de/Garmin-010-01055-15-GLO-GPS-Empf%C3%83%C2%A4nger-Schwarz/dp/B00AYAZENY/">
                                    GARMIN GLO device
                                </a>
                                .
                            </p>
                            <h1 id="find-out-where-to-get-faster">
                                Find out where to get faster
                            </h1>
                            <p>
                                Right after your first laps you can grab your
                                phone and take a look at your section times and
                                compare your laps.
                            </p>
                            <ol>
                                <li>Enable the time delta chart metric</li>
                                <li>
                                    Look at time delta peeks and find your
                                    fastest sections times
                                </li>
                                <li>
                                    Compare your line, corner entry speed and
                                    speed at the apex
                                </li>
                            </ol>
                            {/*kg-card-end: markdown*/}
                            <figure className="kg-card kg-image-card">
                                <img
                                    src="/images/blog/2021/06/RaceChrono-2.gif"
                                    className="kg-image"
                                    alt
                                    loading="lazy"
                                    width={350}
                                    height={645}
                                />
                            </figure>
                            {/*kg-card-begin: markdown*/}
                            <h2 id="dig-deeper-on-a-big-screen">
                                Dig Deeper on a big screen
                            </h2>
                            <p>
                                After each track day I am exporting my sessions
                                from RaceChrono as VBO files and open them on my
                                Mac or Windows PC using the free{" "}
                                <a href="https://www.vboxmotorsport.co.uk/index.php/en/customer-area/software">
                                    Circuit Tools desktop software
                                </a>
                                . When heading to the next track day I also look
                                at my last sessions and define corners and
                                sections to work on.
                            </p>
                            {/*kg-card-end: markdown*/}
                            <figure className="kg-card kg-image-card">
                                <img
                                    src="/images/blog/2021/06/racelogic-karting-2.jpg"
                                    className="kg-image"
                                    alt
                                    loading="lazy"
                                    width={1200}
                                    height={754}
                                    srcSet="/images/blog/size/w600/2021/06/racelogic-karting-2.jpg 600w, /images/blog/size/w1000/2021/06/racelogic-karting-2.jpg 1000w, /images/blog/2021/06/racelogic-karting-2.jpg 1200w"
                                    sizes="(min-width: 720px) 720px"
                                />
                            </figure>
                        </div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

export default Post 